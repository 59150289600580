// console.clear();

import img1 from "/images/profile-photos/1.webp";
import img2 from "/images/profile-photos/2.webp";
// import img3 from "./profile-photos/3.webp";
import img4 from "/images/profile-photos/4.webp";
import img6 from "/images/profile-photos/6.webp";
import img7 from "/images/profile-photos/7.webp";
import img8 from "/images/profile-photos/8.webp";

(function () {
    var domDisplayList = document.querySelector("#blog-entries");
    function renderBlogs() {
	frag = document.createDocumentFragment();
	for (var i = 1; i < 2; i++) {
	    var link = document.createElement("a");
	    link.textContent = "one";
	    link.setAttribute("href", "/blog-entries/" + i + ".html");
	    var li = document.createElement("li");
	    li.appendChild(link);
	    frag.appendChild(li);
	}
	console.log(frag);
	domDisplayList.innerHTML = "";
	domDisplayList.appendChild(frag);
    }
    // renderBlogs()
})();

(function () {

    function renderPhoto() {
	var domProfilePhotos = document.querySelectorAll("img.profile-photo");
	// var photos = [img1,img2,img4,img6];
	var photos = [img4,img7,img7,img8,img8];
	var random = Math.floor(Math.random() * photos.length);
	domProfilePhotos.forEach (domImg => {
	    // console.log(random);
	    domImg.setAttribute("src", photos[random]);
	});
    }

    renderPhoto();
})();


(function () {

    function toggleWeblog() {
	var allcontent0 = document.querySelectorAll(".weblog-content");
	allcontent0.forEach (elt => {
	    elt.style.display = "none";
	});
	var alltoggles0 = document.querySelectorAll(".weblog-toggle");
	alltoggles0.forEach (elt => {
	    var year = elt.dataset.year;
	    elt.text = "+ " + year;
	});

	var currentyear = "2023";
	var togglecurrent = document.querySelector("#weblog-toggle-" + currentyear);
	togglecurrent.text = "- " + currentyear;
	var contentcurrent = document.querySelector("#weblog-content-" + currentyear);
	contentcurrent.style.display = "block";


	var toggles = document.querySelectorAll("a.weblog-toggle");
	toggles.forEach (elt => {
	    elt.addEventListener("click", function () {
		console.log(elt.dataset.year);
		// selectively show.
		var year = elt.dataset.year;
		var content = document.querySelector("#weblog-content-" + year);
		if (content.style.display === "block") {
		    content.style.display = "none";
		    elt.text = "+ " + year;
		} else {
		    content.style.display = "block";
		    elt.text = "- " + year;
		}
	    });
	});
    }

})();
